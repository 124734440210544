<template>
  <div>
    <div class="section section__course">
      <template v-if="course">
        <div class="section__wrapper">
          <div class="row d-md-none">
            <div class="col-md-8">
              <div class="title-with-icon mb-4">
                <div class="title-with-icon__icon title-with-icon__icon_about-school"></div>
                <div class="title-with-icon__title">
                  {{ $route.params.program === 'jiznennyy-cikl-eksportnogo-proekta' ? 'Семинар' : 'Курс обучения' }}
                </div>
              </div>
              <h1>{{ course.title }}</h1>
            </div>
            <div class="col-md-4">
              <div class="module__teaser">
                <div class="module__teaser-image">
                  <img v-if="course.preview" :src="course.preview['900x']"/>
                  <img v-else src="/pic/course-img.jpeg"/>
                </div>
                <div v-if="course.modules.length" class="module__teaser-count">{{ course.modules.length }} {{ course.modules.length | pluralize('ru', ['модуль', 'модуля', 'модулей']) }}</div>
                <div v-if="course.tutorials.length" style="font-size: 2.2rem;" class="module__teaser-count">{{ course.tutorials.length }} {{ course.tutorials.length | pluralize('ru', ['документ', 'документа', 'документа']) }}</div>
              </div>
            </div>
          </div>

          <div class="title-with-icon mb-4 d-md-inline-flex d-none">
            <div class="title-with-icon__icon title-with-icon__icon_about-school"></div>
            <div class="title-with-icon__title">
              {{ $route.params.program === 'jiznennyy-cikl-eksportnogo-proekta' ? 'Семинар' : 'Курс обучения' }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="section__course-content">
                <h1 class="d-md-block d-none">{{ course.title }}</h1>
                <p>{{ course.short_desc }}</p>
                <div class="d-block d-md-none" v-if="program !== 'upravlenie-biznesom-i-organizaciya-eksportnoy-deyatelnosti'">
                  <div class="d-inline-flex my-5">
                    <div class="rating mr-2">
                      <span class="filled"></span>
                      <span class="filled"></span>
                      <span class="filled"></span>
                      <span class="filled"></span>
                      <span class="semi-filled"></span>
                    </div>
                    <div class="rating__feedbacks">
                      <a data-toggle="tab" href="#discuss" class="discuss" role="tab" aria-controls="discuss">{{course.review_count}} отзыва</a>
                    </div>
                  </div>
                </div>

                <ul class="nav nav-tabs_underlined mt-4 mb-5" id="myTab3" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="description-tab" data-toggle="tab" href="#description" role="tab"
                       aria-controls="description"
                       aria-selected="true">Описание</a>
                  </li>
                  <li v-if="!course.is_tutorial && course.id !== 18 && course.program.id !== 6 && program !== 'magisterskaya-programma-vavt-minekonomrazvitiya-rossii'" class="nav-item" role="presentation">
                    <a class="nav-link" id="online-tab" data-toggle="tab" href="#online" role="tab" aria-controls="online"
                       aria-selected="false">Онлайн обучение</a>
                  </li>
                  <li v-if="!course.is_tutorial && events" class="nav-item" role="presentation">
                    <a class="nav-link" id="seminars-tab" data-toggle="tab" href="#seminars" role="tab"
                       aria-controls="seminars"
                       aria-selected="false">Семинары</a>
                  </li>
                  <li v-if="course.program.id !== 6 && program !== 'magisterskaya-programma-vavt-minekonomrazvitiya-rossii'" class="nav-item" role="presentation">
                    <a class="nav-link" id="discuss-tab" data-toggle="tab" href="#discuss" role="tab" aria-controls="discuss"
                       aria-selected="false">Отзывы</a>
                  </li>
                  <li class="nav-tabs_underlined-indicator"></li>
                </ul>

                <div class="tab-content" id="myTab3Content">
                  <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                    <template v-if="!course.is_tutorial">
                      <h2 v-if="course.program.about" class="mb-3">{{ course.program.about }}</h2>
                      <h2 v-else class="mb-3">Об этом курсе</h2>
                    </template>
                    <template v-else>
                      <h2 v-if="course.program.about" class="mb-3">{{ course.program.about }}</h2>
                      <h2 v-else class="mb-3">Об этом пособии</h2>
                    </template>
                    <div class="mb-5" v-html="course.full_desc"></div>
                    <div class="d-md-none">
                      <div class="accordion" id="course-description">
                        <div class="accordion__item container-shadow">
                          <div class="accordion__header" id="headingOne">
                            <h2 v-if="!course.program.you_know" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">А знаете ли вы, что</h2>
                            <h2 v-else data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">{{ course.program.you_know }}</h2>
                          </div>

                          <div id="collapseOne" class="collapse show accordion__body" aria-labelledby="headingOne" data-parent="#course-description">
                            <div class="section__course-text" v-html="course.what_learn"></div>
                          </div>
                        </div>

                        <div class="accordion__item container-shadow">
                          <div class="accordion__header" id="headingTwo">
                            <h2 v-if="course.program.about" class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                              {{ course.program.about }}
                            </h2>
                            <h2 v-else class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                              О чем этот курс
                            </h2>
                          </div>

                          <div id="collapseTwo" class="collapse accordion__body" aria-labelledby="headingOne" data-parent="#course-description">
                            <div class="section__course-text">
                              <ul class="list list_lined">
                                <li>Пошаговый анализ всего жизненного цикла экспортного проекта</li>
                                <li>Алгоритм оценки готовности вашего предприятия к осуществлению экспортной деятельности</li>
                                <li>Методические рекомендации по подготовке основных документов, необходимых для заключения внешнеторговой
                                  сделки
                                </li>
                                <li>Порядок проверки надежности иностранного контрагента</li>
                                <li>Онлайновые источники информации, полезные для экспортеров</li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div v-if="course.promo" class="container-shadow preview-video mb-5" :style="{backgroundImage: 'url(' + (course.promo.cdn_snapshot ?  course.promo.cdn_snapshot : '/pic/course-img.jpeg') + ')'}">
                      <a data-fancybox :href="course.promo.formats[0].file" class="container-shadow">
                        <div class="arrow-button"></div>
                      </a>
                    </div>
                    <div class="mb-5" v-html="course.program.documents_for_course"></div>
                    <h2 v-if="course.tutors.length" class="mb-3">Авторы</h2>
                    <author v-for="tutor in course.tutors" :tutor="tutor" :key="tutor.id" />
                  </div>
                  <div v-if="course.id !== 18" class="tab-pane fade" id="online" role="tabpanel" aria-labelledby="online-tab">
                    <h2 class="mb-3">Онлайн обучение</h2>
                    <div class="module__prefix mb-3">Дистанционные курсы для удобства слушателей разделены на тематические
                      модули.
                      Каждый тематический модуль включает в себя несколько видео-лекций продолжительностью не более
                      пятнадцати
                      минут и проверочные тесты в формате закрытых вопросов. Модули рекомендуется проходить в указанной
                      последовательности, параллельно выполняя упражнения в рабочей тетради, приложенной для скачивания.
                    </div>
                    <div class="module container-shadow" v-for="(module, moduleKey) in course.modules" :key="moduleKey">
                      <div class="module__header d-flex justify-content-between mb-2">
                        <div class="module__title">Модуль {{ moduleKey + 1 }}</div>
                        <div class="module__duration">{{ module.videos_count }} видеолекций</div>
                      </div>
                      <div class="module__image mb-2" :style="{backgroundImage: module.cover ? 'url(' + module.cover['500x'] + ')' : '' }"></div>
                      <h2 class="module__name mb-1"><a href="#">{{ module.title }}</a></h2>
                      <div class="module__description" v-html="module.description"></div>
                      <div class="module__counts mb-1">Список видеолекций</div>
                      <ul class="list list_dotted">
                        <li v-for="(video, key) in module.video_fragments" :key="key">
                          <a class="video-title" :href="`/course/${course.id}/module/${moduleKey + 1}/lecture/${key + 1}`">
                            <span class="tooltip-video" :style="{backgroundImage:  video.snapshot_about ? 'url(' + video.snapshot_about['1280x720'] + ')' : '' }"></span>
                            {{ video.title }}
                          </a>
                        </li>
                      </ul>
                      <router-link to="/lk/online" class="link-target"><span>Перейти к обучению</span></router-link>
                    </div>
                  </div>
                  <div v-if="events" class="tab-pane fade" id="seminars" role="tabpanel" aria-labelledby="seminars-tab">
                    <h2 class="mb-3">Семинары</h2>
                    <div class="mb-3">
                      <p>Очное обучение по курсу состоит из мини-лекций и практических заданий, выполняемых в группах. Курсы
                        Школы экспорта могут быть пройдены слушателем в регионах реализации Программы. Чтобы записаться на
                        интересующий вас курс, выберите подходящие дату, время и регион проведения курса. Если этого курса нет
                        в календаре ближайших мероприятий, оставьте заявку на его проведение внизу страницы, и региональный
                        оператор свяжется с вами.</p>
                    </div>
                    <seminar-item v-for="(event, index) in events" :key="index" :event="event" />
                  </div>
                  <div class="tab-pane fade" id="discuss" role="tabpanel" aria-labelledby="discuss-tab">
                    <h2 class="mb-3">Отзывы участников</h2>
                    <template v-if="reviews && reviews.length">
                      <comment-item v-for="review in reviews" :key="review.id" :review="review" />
                    </template>
                    <div v-else class="section__course-discussion-empty">
                      <div class="section__course-discussion-zoom"></div>
                      <div class="section__course-discussion-title">нет ни одного отзыва</div>
                      <div class="section__course-discussion-text">Тут пока еще ничего не обсуждали. Попробуйте вернуться на эту страницу позже или оставьте свой комментарий.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 d-md-block d-none">
              <div class="module__teaser">
                <div class="module__teaser-image">
                  <img v-if="course.preview" :src="course.preview['500x']"/>
                  <img v-else src="/pic/course-img.jpeg"/>
                </div>
                <template v-if="program && program !== 'jiznennyy-cikl-eksportnogo-proekta'">
                  <div v-if="course.modules.length" class="module__teaser-count">{{ course.modules.length }} {{ course.modules.length | pluralize('ru', ['модуль', 'модуля', 'модулей']) }}</div>
                  <div v-if="course.tutorials.length" style="font-size: 2.2rem;" class="module__teaser-count">{{ course.tutorials.length }} {{ course.tutorials.length | pluralize('ru', ['документ', 'документа', 'документа']) }}</div>
                </template>
              </div>
              <div class="d-inline-flex my-5" v-if="program !== 'upravlenie-biznesom-i-organizaciya-eksportnoy-deyatelnosti' && program !== 'magisterskaya-programma-vavt-minekonomrazvitiya-rossii'">
                <div class="rating mr-2">
                  <span class="filled"></span>
                  <span class="filled"></span>
                  <span class="filled"></span>
                  <span class="filled"></span>
                  <span class="semi-filled"></span>
                </div>
                <div class="rating__feedbacks">
                  <a data-toggle="tab" href="#discuss" class="discuss" role="tab" aria-controls="discuss">{{ course.review_count }} отзыва</a>
                </div>
              </div>
              <div v-else class="mt-5"></div>

              <h2 v-if="!course.program.you_know" class="mb-3">А знаете ли вы, что</h2>
              <h2 v-else class="mb-3">{{ course.program.you_know }}</h2>
              <div class="section__course-text mb-3" v-html="course.what_learn"></div>

              <h2 v-if="!course.program.in_course && !course.is_tutorial" class="mb-3">В этом курсе</h2>
              <h2 v-else class="mb-3">{{ course.program.in_course }}</h2>
              <div class="section__course-text" v-html="course.that_learn"></div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import $ from 'jquery';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
import ApiService from "../../services/api.service";
import Author from "../components/Author";
import SeminarItem from "../components/SeminarItem";
import CommentItem from "../components/CommentItem";
import PageFooter from "../components/PageFooter";
window.jQuery = $
export default {
  name: "Course",
  components: {PageFooter, CommentItem, SeminarItem, Author},
  data() {
    return {
      course: null,
      events: null,
      reviews: null,
      program: null
    }
  },
  mounted() {
    require('@fancyapps/fancybox')
    $('[data-fancybox]').fancybox({
      video: {
        tpl:
            '<video class="fancybox-video" controls controlsList="nodownload" poster="{{poster}}">' +
            '<source src="{{src}}" type="{{format}}" />' +
            "</video>",
        format: "", // custom video format
        autoStart: true
      },
    });
    let course = this.$route.params.course
    this.program = this.$route.params.program
    ApiService.get(`courses/${course}`, {
      params: {
        program: this.program
      }
    }).then(res => {
      this.course = res.data
      this.getEvents()
      this.getReviews()
      this.$parent.init()
      setTimeout(() => {
        $('.discuss').click(function(){
          window.location.href += "#discuss";
          location.reload();
        })
      }, 1000)
    })
  },
  methods: {
    getEvents() {
      ApiService.get(`courses/${this.course.alias}/events`).then(res => {
        this.events = res.data
      })
    },
    getReviews() {
      ApiService.get(`courses/${this.course.id}/reviews`).then(res => {
        this.reviews = res.data
      })
    }
  }
}
</script>
<style>
  .module__image {
    background-size: cover;
  }
</style>