<template>
  <section class="section section__front" id="section0">
    <div class="section__front-video-wrapper">
      <video class="section__front-video" id="front-video" loop="" muted="" autoplay="" data-keepplaying="">
        <source src="/video/front-video.mp4" type="video/mp4">
        <!--    <source src="images/video-front.webm" type="video/webm">-->
      </video>
    </div>

    <div class="section__wrapper">
      <lottie class="section__front-animation" id="front-animation" v-on:animCreated="handleAnimation" :options="defaultOptions"/>
      <div class="row">
        <div class="col-md-6 section__front-description">
          <div class="title-with-icon mb-3 mb-md-5">
            <div class="title-with-icon__icon title-with-icon__icon_download"></div>
            <div class="title-with-icon__title">Школа экспорта РЭЦ</div>
          </div>
          <div class="section__front-slogan">
            <h2 class="text-uppercase section__front-slogan-1">
              <span>Экспортировать</span>
              <span class="text-primary">не так просто,</span>
              <span>как кажется</span>
            </h2>
            <h2 class="text-uppercase section__front-slogan-2">
              <span>Начнем</span>
              <span>экспортировать</span>
              <span class="text-secondary">вместе</span>
            </h2>
          </div>
          <a href="/education" class="link-target"><span>Перейти к обучению</span></a>
        </div>
        <div class="col-md-6 section__front-carousel">
          <div class="title-with-icon mb-4 mb-md-2">
            <div class="title-with-icon__icon title-with-icon__icon_how-learn d-none d-xl-block"></div>
            <div class="title-with-icon__title d-none d-xl-block">
              <a href="https://exportedu.ru/mentoring">
                Акселерация. Экспортное наставничество
              </a>
            </div>
          </div>
          <div class="carousel-wrapper d-none d-xl-block">
            <div class="owl-carousel__calendar-wrapper">
              <div class="owl-carousel__calendar" :class="{'owl-carousel': events.length > 1, 'owl-hidden': events.length === 1}">
                <div class="item">
                  <div class="calendar-item">
                    <div class="calendar-item__date">
                      <div class="crisis-wrapper">
                        <div class="section__school-container-img">
                          <img src="/pic/icon-school-new.svg">
                        </div>
                        <div class="section__education-tag-name mb-1">Новый продукт</div>
                        <div class="section__school-container-title navy-text">
                          Экспортное<br/>наставничество
                        </div>
                      </div>
                    </div>
                    <div class="calendar-item__image" style="background-image: url('/pic/truks.jpg')"></div>
                    <div class="calendar-item__data">
                      <div class="calendar-item__description">
                        <p>
                          Уникальный продукт, направленный на индивидуальную проработку экспортного проекта компании!
                        </p>
                      </div>
                      <a href="https://exportedu.ru/mentoring" class="link-target">
                        <span>
                          <span style="display: block; position: absolute;">Посмотреть</span>
                          <span style="opacity: 0;">Участвовать</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title-with-icon mb-4 mb-md-2">
            <div class="title-with-icon__icon title-with-icon__icon_calendar"></div>
            <div class="title-with-icon__title">
              <a href="/calendar">Календарь обучения</a>
            </div>
          </div>
          <div v-if="events" class="owl-carousel__calendar-wrapper">
            <div class="owl-carousel__calendar" :class="{'owl-carousel': events.length > 1, 'owl-hidden': events.length === 1}">
              <template v-for="(event, index) in events">
                <div :key="index" class="item">
                  <div class="calendar-item">
                    <div class="calendar-item__date">
                      <div class="calendar-item__date-num">{{ event.startTime | formatDate('D') }}</div>
                      <div class="calendar-item__date-month"><span>{{ event.startTime | formatDate('M') }} {{ event.startTime | formatDate('Y') }}</span></div>
                      <div class="calendar-item__location-wrapper">
                        <div v-if="event.type !== 'anticris'" class="calendar-item__location">
                          {{ event.city && event.city.length ? event.city : 'Москва'  }}
                        </div>
                        <div v-else class="calendar-item__type__anticris">
                          Online
                        </div>
                      </div>
                    </div>
                    <div class="calendar-item__image" :style="{backgroundImage: 'url(' + (event.cover ? event.cover['500x'] : '/images/event.jpeg') + ')' }"></div>
                    <div class="calendar-item__data">
                      <div class="calendar-item__type"><template v-if="event.type === 'anticris'"></template> {{ event.api_type }}</div>
                      <div class="calendar-item__description">
                        {{ event.title }}
                      </div>
                      <a :href="event.type === 'anticris' ? event.external_link : `/events/${event.id}`" class="link-target"><span>Участвовать</span></a>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="fp-alert d-none d-lg-block" @click="toRecommend">
        <div class="mb-3">
          <p>
            <img src="/pic/front/chat.svg"/>
          </p>
          <h3>
            Пройдите тест, и мы бесплатно подберем для Вас подходящие образовательные программы!
          </h3>
        </div>
        <div class="text-right">
          <a href="/lk/recommend" class="link-target"><span>Пройти тест</span></a>
        </div>
        <img class="marker" src="/pic/front/marker.svg" />
      </div>
    </div>
  </section>
</template>

<script>
import Lottie from 'vue-lottie';
import animation from '../../public/lottie/animation.json';
export default {
  name: "Front",
  components: {Lottie},
  data() {
    return {
      defaultOptions: {
        renderer: 'svg', // required
        // path: 'lottie-front.json', // required
        animationData: animation,
        loop: false, // optional
        autoplay: true, // optional
        name: "Demo Animation Front", // optional
      },
      animationSpeed: 1
    }
  },
  props: ['events'],
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      this.anim.addEventListener('complete', this.onAnimComplete)
    },
    onAnimComplete() {
      const posRect = this.$el.querySelector('[clip-path="url(#__lottie_element_2)"]').getBoundingClientRect();
      let alert = this.$el.querySelector('.fp-alert')
      let alertRect = alert.getBoundingClientRect()
      alert.classList.add('alert-is-shown')
      const padding = posRect.width/6
      alert.style.top = posRect.top + padding + 'px'
      alert.style.left = (posRect.right - alertRect.width - padding) + 'px'
      setTimeout(() => {
        alert.classList.remove('alert-is-shown')
      }, 25000)
    },
    toRecommend() {
      window.location.href = '/profile/recommend';
    }
  },
}
</script>

<style scoped lang="scss">
.section__school-container_col.crisis {
  max-height: none;
  flex: none;
  padding: 0;
  width: calc(25% + 19.1rem);
  .crisis-wrapper {
    color: #002E5E;
    a {
      color: #002E5E;
      text-decoration: none;
      align-items: center;
      padding-right: 3.2rem;
      background-size: 2.4rem auto;
      background-repeat: no-repeat;
      transition: 0.3s ease-in-out;
      background-position: 100% 50%;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath class='circle' d='M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z' fill='transparent'/%3E%3Cpath class='arrow' d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z' fill='%23002E5E'/%3E%3C/svg%3E");
    }
  }
}
.section__school-container-title {
  white-space: nowrap;
}
.fadeIn {
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 5s; /* Firefox < 16 */
  -ms-animation: fadein 5s; /* Internet Explorer */
  -o-animation: fadein 5s; /* Opera < 12.1 */
  animation: fadein 5s;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
.carousel-wrapper {
  min-height: 194px;
}
.navy-text {
  color: #002E5E;
}
.fp-alert-point {
  background: red;
  position: absolute;
  width: 10px;
  height: 10px;
}
.fp-alert {
  position: absolute;
  background: #fff;
  width: 30%;
  padding: 2.4rem;
  box-shadow: 0 1.6rem 2.4rem rgb(0 0 0 / 8%), 0 0.2rem 0.6rem rgb(0 0 0 / 4%), 0 0 0.1rem rgb(0 0 0 / 4%);
  transform: translateY(-20%) translateX(20%);
  transition-timing-function: ease-in;
  transition: 0.8s;
  opacity: 0;
  z-index: 1000;
  border-bottom: 0;
  @media (max-width: 1366px) {
    width: 28%;
  }
  &.alert-is-shown {
    transition: 0.8s;
    transition-timing-function: ease-out;
    transform: translateY(0);
    opacity: 1;
  }
}
.marker {
  display: inline-block;
  position: absolute;
  left: 12px;
  bottom: 12px;
}
</style>